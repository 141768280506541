/*!
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

@import '@opengamma/ui/src/styles/_variables';

// Overwrite highcharts's default menu item for styling

.highcharts-menu {
  box-shadow: none !important;
}

.highcharts-menu-item:hover {
  background-color: $mine-shaft-lighter !important;
}

/** mat-select */
.cdk-overlay-pane-select-search {
  margin-top: 20px !important;
  margin-left: 15px;
}

.mat-mdc-select-search-input {
  height: 50px;
}

.mat-mdc-select-value-text {
  font-weight: $font-weight--strong;
}

.mat-mdc-form-field-underline {
  background-color: rgba(255, 255, 255, 0.12) !important;
}
.mat-mdc-form-field-disabled {
  .mat-mdc-form-field-underline {
    background-image: none !important;
  }
}

.multi-select__field--no-border {
  .mat-mdc-form-field-underline {
    background-color: transparent !important;
  }
}

.mat-mdc-select-search-no-entries-found {
  color: $white;
}

.mat-mdc-option-text {
  display: flex !important;
  font-size: $font-size--sm;
  font-weight: $font-weight--strong;
}

/** button group */
.mat-button-toggle-label-content {
  line-height: inherit !important;
  padding: 8px 16px !important;
  background: transparent !important;
  font-weight: $font-weight--strong;
}

.mat-button-toggle-group {
  border: 1px solid $quarter-white !important;
  border-radius: 0 !important;
}

mat-button-toggle {
  background: transparent !important;

  &.mat-button-toggle-checked {
    background: transparentize(white, 0.85) !important;
  }
}

.mat-mdc-form-field {
  font-weight: $font-weight--strong;
}

.og-mat-tab {
  .mat-mdc-tab-body-wrapper {
    box-shadow: $shadow-elevation-2;
    height: 100%;
    overflow: visible;
  }

  .mat-mdc-tab-body.mat-mdc-tab-body-active {
    overflow: visible;
  }

  .mat-mdc-tab-body-content {
    overflow: visible;
  }

  .mat-mdc-tab-header {
    border: none;
  }

  .mdc-tab {
    height: auto;
    background-color: $layout--4;
    border-radius: 8px 8px 0 0;
    font-size: $font-size--md;
    margin-right: $spacing-md;
    min-width: 250px;
    opacity: 1;
    box-shadow: $shadow-elevation-1;
    --mdc-tab-indicator-active-indicator-height: 0px;

    & > .mdc-tab-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  .mdc-tab__text-label {
    --mdc-tab-indicator-active-indicator-color: #{$white};
    --mat-tab-header-disabled-ripple-color: #{$white};
    --mat-tab-header-pagination-icon-color: #{$white};
    --mat-tab-header-inactive-label-text-color: #{$white};
    --mat-tab-header-active-label-text-color: #{$white};
    --mat-tab-header-active-ripple-color: #{$white};
    --mat-tab-header-inactive-ripple-color: #{$white};
    --mat-tab-header-inactive-focus-label-text-color: #{$white};
    --mat-tab-header-inactive-hover-label-text-color: #{$white};
    --mat-tab-header-active-focus-label-text-color: #{$white};
    --mat-tab-header-active-hover-label-text-color: #{$white};
    --mat-tab-header-active-focus-indicator-color: #{$white};
    --mat-tab-header-active-hover-indicator-color: #{$white};
  }

  .mdc-tab:focus:not(.mat-mdc-tab-disabled) {
    opacity: 1;
  }

  &.single-tab .mdc-tab:hover {
    cursor: default;
    background-color: $layout--3;
  }

  .mdc-tab--active {
    background-color: $layout--2;
    box-shadow: $shadow-elevation-2;
  }

  .cdk-focused .cdk-mouse-focused {
    background-color: $layout--2;
  }

  .mat-mdc-ink-bar {
    display: none;
  }

  &--use-background-palette {
    background-color: inherit;

    .mdc-tab {
      background-color: $layout--3;
    }

    .mdc-tab--active {
      background-color: $layout--1;
    }

    .cdk-focused .cdk-mouse-focused {
      background-color: $layout--1;
    }

    .mat-mdc-tab-body-wrapper {
      background-color: $layout--1;
    }
  }

  &--small {
    .mdc-tab {
      margin-right: $spacing-md;
      min-width: auto;
      padding: $spacing-md;
    }
  }

  &--large {
    .mdc-tab {
      height: 80px;
      margin-right: $spacing-md;
      padding: $spacing-md;
    }
  }
}

.og-checkbox {
  .mat-mdc-ripple-element {
    background-color: transparent;
  }

  &.mat-mdc-checkbox-indeterminate.mat-mdc-accent .mat-mdc-checkbox-background,
  &.mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background {
    background-color: var(--text--accent);
  }

  .mat-mdc-checkbox-inner-container {
    height: 14px;
    width: 14px;
    border-width: 1px !important;
  }

  .mat-mdc-checkbox-frame {
    border: 1px solid var(--text--accent);
  }

  .mat-mdc-checkbox-checked .mat-mdc-checkbox-background {
    background-color: var(--text--accent);
  }
}

.og-radio {
  .mat-mdc-radio-outer-circle {
    border: 2px solid var(--text--accent);
  }

  .mat-mdc-radio-persistent-ripple {
    background-color: transparent !important;
  }

  .mat-mdc-radio-container {
    width: 14px;
    height: 14px;
  }
}

.og-market-data {
  .mdc-tab {
    background-color: $layout--4;
    border-radius: 8px 8px 0 0;
    font-size: $font-size--md;
    margin-right: $spacing-md;
    min-width: 250px;
    opacity: 1;
    box-shadow: $shadow-elevation-1;

    & > .mdc-tab-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #{$mine-shaft};
}

.dialog--no-padding {
  .mat-mdc-dialog-container {
    padding: 0;
  }
}

::ng-deep .mat-mdc-tooltip {
  white-space: pre-line;
}

.mat-mdc-option {
  line-height: 1em !important;
  white-space: inherit !important;
  height: 4em !important;
  padding-right: $spacing-sm !important;
}

.mat-mdc-form-field-appearance-legacy
  .mat-mdc-form-field-suffix
  .mat-mdc-datepicker-toggle
  .mat-mdc-icon-button {
  margin-bottom: $spacing-unit;
}
