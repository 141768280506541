/*!
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
@use '@angular/material' as mat;

@import '@opengamma/ui/src/styles/_variables';

$mat-blue-gray-primary: mat.m2-define-palette(mat.$m2-light-blue-palette, 700);
$mat-blue-gray-accent: mat.m2-define-palette(mat.$m2-light-blue-palette, 800);
$mat-blue-gray-warn: mat.m2-define-palette(mat.$m2-red-palette);
mat.$m2-blue-gray-palette: mat.m2-define-dark-theme(
  (
    color: (
      primary: $mat-blue-gray-primary,
      accent: $mat-blue-gray-accent,
      warn: $mat-blue-gray-warn
    ),
    typography:
      mat.m2-define-legacy-typography-config(
        $subheading-1: mat.m2-define-typography-level(13px, 24px, 400)
      )
  )
);
