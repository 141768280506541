/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

@import '@opengamma/ui/src/styles/_variables';

.og-calendar {
  .mydp {
    border-radius: 0px !important;

    .selector {
      border: 0px !important;
    }

    .headerbtncell,
    .headermonthtxt,
    .headeryeartxt {
      background-color: $omega !important;

      > button {
        color: $white;
        background-color: $omega !important;
      }
    }

    .weekdaytitle {
      padding: 5px;
      font-size: 13px;
      background-color: $omega;
      color: $white;
    }

    .selectedday {
      background-color: $tundora;

      .datevalue {
        background-color: $tundora;
        color: $white;
      }

      &:hover {
        background-color: $tundora;
      }
    }

    .selectedyear .yearvalue {
      background-color: $omega;
    }

    .selectedmonth .monthvalue {
      background-color: $omega;
    }
  }

  .header {
    border-radius: 0px !important;
    background-color: $omega !important;
    border-bottom: 1px solid $theta;
  }

  .caltable {
    border-radius: 0px !important;

    tbody tr:nth-child(6) {
      td:first-child {
        border-bottom-left-radius: 0px !important;
      }

      td:last-child {
        border-bottom-right-radius: 0px !important;
      }
    }
  }
}
