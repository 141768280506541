/*!
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
@use '@angular/material' as mat;

@import '@opengamma/ui/src/styles/_variables';

@import 'custom-calendar';

@import 'mat-theme';

@include mat.core();
@include mat.all-component-themes(mat.$m2-blue-gray-palette);

@import '@opengamma/ui/src/styles/_globals';

@font-face {
  font-family: 'Regular';
  font-weight: $font-weight--regular;
  src: url('../assets/fonts/Regular-MediumWEB.woff2');
}

@font-face {
  font-family: 'Regular';
  font-weight: $font-weight--strong;
  src: url('../assets/fonts/Regular-SemiboldWEB.woff2');
}
